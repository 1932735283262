import RawHtml from '@components/RawHtml';
import React from 'react';

type NotificationItem = {
  type: string;
  title?: string;
  text: string;
};

type Props = {
  notificationItem: NotificationItem;
};

function Notification(props: Props) {
  const { notificationItem } = props;
  return (
    <section className='notification__section'>
      <div className={`notification notification--${notificationItem.type}`}>
        {notificationItem.title ? (
          <h2 className='notification__headline'>{notificationItem.title}</h2>
        ) : null}
        <RawHtml className='richtext-wrapper' html={notificationItem.text} />
      </div>
    </section>
  );
}

export default Notification;
